/**
 * Various functions for internal (within directus) membership data
 */
export function useIsActive() {
  const user = useUser().value.user;
  if (!user) return false;

  const allowedStatuses = ["active", "past_due"];

  return user.membership_status && allowedStatuses.includes(user.membership_status);
}
